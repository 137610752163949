body {
  counter-reset: h2;
}
h2 {
  counter-reset: h3;
}
h3 {
  counter-reset: h4;
}
h4 {
  counter-reset: h5;
}
h5 {
  counter-reset: h6;
}
h2:before {
  counter-increment: h2;
  content: counter(h2) '. ';
}
h3:before {
  counter-increment: h3;
  content: counter(h2) '.' counter(h3) '. ';
}
h4:before {
  counter-increment: h4;
  content: counter(h2) '.' counter(h3) '.' counter(h4) '. ';
}
h5:before {
  counter-increment: h5;
  content: counter(h2) '.' counter(h3) '.' counter(h4) '.' counter(h5) '. ';
}
h6:before {
  counter-increment: h6;
  content: counter(h2) '.' counter(h3) '.' counter(h4) '.' counter(h5) '.'
    counter(h6) '. ';
}
h2.no-count:before,
h3.no-count:before,
h4.no-count:before,
h5.no-count:before,
h6.no-count:before {
  content: '';
  counter-increment: none;
}

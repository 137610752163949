* {
  font-family: sans-serif;
  color: #fff;
  box-sizing: border-box;
}

html {
  background-color: #222;
  position: relative;
}

body {
  overflow-y: scroll;
  max-width: 45rem;
  margin: 0 auto;
}

#root {
  position: relative;
}

/* Navbar styling */

nav {
  background-color: #444;
  padding: 0.2rem;
  position: sticky;
  box-shadow: 0 0 1rem #111;
  border-radius: 0 0 0 0;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

nav ul li {
  /* background-color: #222; */
  background: linear-gradient(#333, #111);
  border-radius: 1rem;
  margin: 0.1rem;
  flex-grow: 0.5;
  display: flex;
}

nav ul li a {
  text-decoration: none;
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

nav ul li :hover {
  background-color: #111;
  border-radius: 1rem;
}

.active {
  background-color: #111;
  border-radius: 1rem;
}

.bottom-spacer {
  padding-bottom: 5rem;
}

table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  display: block;
  overflow: auto;
}

th,
td {
  border: 1px solid white;
  border-collapse: collapse;
  text-align: start;
}

th,
td {
  padding: 0.2rem 0.4rem;
}

th {
  text-align: center;
}

.stat-line-special-rules-cell {
  font-style: italic;
}

.centered-h2 {
  text-align: center;
}

/* Scrollbar styling */

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: #333;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Various elements */

.wrapper-battlefield-example {
  overflow: auto;
}

.battlefield-example {
  min-width: 20rem;
  width: 100%;
  max-width: 30rem;
}

.square-base-diagram {
  filter: invert(100%);
  width: 100%;
  padding: 1rem;
}

.center-text {
  text-align: center;
}

.no-border-table-corner {
  border-top: 1px solid #222;
  border-left: 1px solid #222;
}

.outlet-padding {
  padding: 1rem;
}

.accordion-item {
  border: 1px solid white;
  padding: 0 1rem 0 1rem;
  margin: 0 0.2rem 0 0.2rem;
}

.left-padding {
  padding-left: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.unit-thumbnail {
  border-radius: 3rem;
  margin: 1rem;
  box-shadow: 0 0 1rem #111;
}

.page-art {
  height: auto;
  width: 100%;
  display: block;
  border-radius: 5rem;
  box-shadow: 0 0 1rem #111;
}

.start-page-title {
  text-align: center;
  font-size: 3rem;
}

.start-page-subtitle {
  text-align: center;
  font-size: 2rem;
}
